(function ($) {
    $('.modal-link').each(function() {
      $('.modal-link').click(function(e) {
        e.preventDefault();
        var toOpen = $(this).attr("href");
        $(toOpen).addClass("open");
      });
      $('.modal .close').click(function() {
        $('.modal').removeClass('open');
      });
      $('.modal-content').click(function(e) {
        e.stopPropagation();
      });
      $('.modal').click(function() {
        $('.modal').removeClass('open');
      });
    });
})(jQuery);
