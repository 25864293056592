(function ($) {
  $('.ateliers').each(function () {
    $(document).on('click', '.ajax-load', function (e) {
      e.preventDefault();
      var path = $(this).attr('href');
      var windowWidth = $(window).outerWidth();
      var windowHeight = $(window).outerHeight();
      var icon = $(this).parents(".el").find(".icon .inner");
      var iconImgWidth = icon.find("svg").css("width");
      var iconImg = icon.html();
      var iconTop = icon.offset().top - $(window).scrollTop();
      var iconLeft = icon.offset().left;
      var iconWidth = icon.outerHeight();
      var iconHeight = icon.outerWidth();
      var iconColor = icon.css("backgroundColor");
      var circle = $(this).parents(".el").find(".circle");
      var circleTop = circle.offset().top - $(window).scrollTop();
      var circleLeft = circle.offset().left;
      var circleWidth = circle.outerHeight();
      var circleHeight = circle.outerWidth();
      var circleColor = circle.css("backgroundColor");

      $.ajax({
        type: "POST",
        url: path,
        dataType: "html",
        success: function (data) {
          if (!$('.ajax-overlay').length) {
            $(".main-wrapper").append('<div class="ajax-overlay"><div class="icon-overlay"></div><div class="close-overlay-wrapper"><div class="close-overlay"><span>Fermer</span></div></div><div class="inner-overlay"></div></div><div class="bg-overlay"></div>');
          }

          var $content = $(data).find('.atelier');
          $('.ajax-overlay .inner-overlay').html($($content));
        },
        complete: function () {
          $(".icon-overlay").css({
            'top': iconTop + 'px',
            'left': iconLeft + 'px',
            'width': iconWidth + 'px',
            'height': iconHeight + 'px',
            'backgroundColor': '#fff'
          });
          $(".icon-overlay").html(iconImg);
          $(".icon-overlay svg").css({
            'width': iconImgWidth,
            'fill': iconColor
          });

          $(".bg-overlay").css({
            'top': circleTop + 'px',
            'left': circleLeft + 'px',
            'width': circleWidth + 'px',
            'height': circleHeight + 'px',
            'backgroundColor': circleColor
          });

          $(".atelier .cell.horaire").css({
            'color': circleColor
          });

          $("body").addClass("overlay-open");
          $(".ajax-overlay").show();
          $(".bg-overlay").show();
          setTimeout(function () {
            $(".ajax-overlay").addClass('show');
          }, 20);
        }
      });
    });

    $(document).on("click", ".ajax-overlay .close-overlay", function (e) {
      e.preventDefault();

      $(".ajax-overlay").removeClass('show');
      $(".atelier").one('transitionend', function (e) {
        $(".ajax-overlay").hide();
        $(".bg-overlay").hide();
        $("body").removeClass("overlay-open");
      });
    });

  });
})(jQuery);
