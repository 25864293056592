(function ($) {
  $(".ateliers").each(function() {
    var el = $(this).find(".el");
    var userScroll;

    el.on("click", function() {
      el.removeClass("open");
      $(this).toggleClass("open");
      userScroll = $(document).scrollTop();
    });

    $(document).on('click', function(e) {
      var $clicked = $(e.target);
      if (!$clicked.parents().hasClass("el") && !$clicked.parents().hasClass("ajax-overlay")) {
        el.removeClass("open");
      }
    });

    /*$(document).scroll(function() {
      if ( $(".ateliers .el").hasClass('open') ) {
        var newScroll = $(document).scrollTop();
        if (userScroll - newScroll > 100 || newScroll - userScroll > 100) {
          el.removeClass("open");
        }
      }
    });*/
  });
})(jQuery);
